import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../components/PageContentLayout';
import { PackageState } from '../components/PackageState';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Develop'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Develop`}</h1>
    <p>{`The framework is delivered for the web platform as `}<a parentName="p" {...{
        "href": "https://www.npmjs.com"
      }}>{`NPM`}</a>{` packages
in the `}<inlineCode parentName="p">{`@arwes/[package]`}</inlineCode>{` scope for `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/CommonJS"
      }}>{`CommonJS`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://github.com/umdjs/umd"
      }}>{`UMD`}</a>{` formats in JavaScript ES5.`}</p>
    <p>{`The packages source code is written in `}<a parentName="p" {...{
        "href": "https://typescriptlang.org"
      }}>{`TypeScript`}</a>{`
and on top of tools like `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`, `}<a parentName="p" {...{
        "href": "https://emotion.sh"
      }}>{`Emotion`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://animejs.com"
      }}>{`Anime`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://howlerjs.com"
      }}>{`Howler`}</a>{`.`}</p>
    <p>{`Latest version of Chrome, Firefox, and Safari, for Android, iOS and desktop are supported.`}</p>
    <p>{`The framework packages are categorized by functional packages and implementation packages.`}</p>
    <h2>{`Functional Packages`}</h2>
    <p>{`The main functionalities which power the UI component system. They can be used to
develop any `}<a parentName="p" {...{
        "href": "https://www.invisionapp.com/inside-design/guide-to-design-systems"
      }}>{`design system`}</a>{`
with similar requirements as the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`Arwes guidelines`}</a>{` propose
to solve.`}</p>
    <h3><inlineCode parentName="h3">{`@arwes/tools`}</inlineCode></h3>
    <PackageState name='tools' status='specification' projectBoard='https://github.com/arwes/arwes/projects/2' mdxType="PackageState" />
    <p>{`Resource loading, responsive control, and general browser API tools.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/develop/design"
      }}><inlineCode parentName="a">{`@arwes/design`}</inlineCode></a></h3>
    <PackageState name='design' status='polishing' projectBoard='https://github.com/arwes/arwes/projects/6' mdxType="PackageState" />
    <p>{`Theming and styling tools with focus in `}<a parentName="p" {...{
        "href": "https://emotion.sh"
      }}>{`Emotion`}</a>{` in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/develop/animation"
      }}><inlineCode parentName="a">{`@arwes/animation`}</inlineCode></a></h3>
    <PackageState name='animation' status='polishing' projectBoard='https://github.com/arwes/arwes/projects/7' mdxType="PackageState" />
    <p>{`Assemble and disassemble user interfaces using animation controls in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/develop/sounds"
      }}><inlineCode parentName="a">{`@arwes/sounds`}</inlineCode></a></h3>
    <PackageState name='sounds' status='polishing' projectBoard='https://github.com/arwes/arwes/projects/4' mdxType="PackageState" />
    <p>{`Define, manage, and control interactive sound effects using `}<a parentName="p" {...{
        "href": "https://howlerjs.com"
      }}>{`Howler`}</a>{`
in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <h2>{`Implementation Packages`}</h2>
    <p>{`Using the functional packages and based on the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`project guidelines`}</a>{`,
these are the framework design system or visual design component implementations.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes/projects/8"
      }}>{`Components GitHub Project`}</a>{`
for all the implementation packages related planning tasks.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/develop/core"
      }}><inlineCode parentName="a">{`@arwes/core`}</inlineCode></a></h3>
    <PackageState name='core' status='development' mdxType="PackageState" />
    <p>{`Framework core set of components.`}</p>
    <h3><inlineCode parentName="h3">{`@arwes/arwes`}</inlineCode></h3>
    <PackageState name='arwes' status='concept' mdxType="PackageState" />
    <p>{`Arwes specific design components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      